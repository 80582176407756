import { useEffect, useState } from 'react';
import { api } from './api.service';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Pricing() {
  return <PricingComponent />;
}

export const PricingComponent = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/app`).then((x) => {
      setEmail(x.companyData.email);
    });
  }, []);

  useEffect(() => {
    if (location.pathname !== '/pricing') {
      localStorage.removeItem('token');
      window.location.replace('/');
    }
  }, [location]);

  const handleLogout = () => {
    navigate('/logout');
  };

  return (
    <div className="relative min-h-screen flex flex-col">
      <button
        onClick={handleLogout}
        className="absolute top-4 right-10 inline-flex items-center px-3 py-2 text-sm font-medium bg-yellow-500 border-2 border-yellow-500 rounded-md text-neutral-800 animate-fade-up animate-delay-100"
      >
        Logout
      </button>
      <div className="flex-grow flex justify-center">
        <stripe-pricing-table
          pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
          publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          customer-email={email}
        />
      </div>
    </div>
  );
};
