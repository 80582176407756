import CurrencyInput from 'react-currency-input-field';

export default function CurrencyField({
  label,
  value,
  onChange,
  className,
  inputClass,
  labelClass,
  onBlur,
  required,
  min,
  max,
  step,
}) {
  return (
    <div className={className + ' mt-2'}>
      <label
        className={`block text-sm font-medium leading-6 text-neutral-800 ${labelClass}`}
      >
        {label} {required && <span className="text-rose-600">*</span>}
      </label>
      <div className="relative inline-block w-full mt-1 overflow-hidden shadow-sm rounded-l-md">
        <div className="absolute inset-y-0 left-0 flex items-center p-3 border rounded-l-md border-gray-100 pointer-events-none bg-gray-50">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        <CurrencyInput
          decimalScale={2}
          required={required}
          onBlur={onBlur}
          value={value}
          onValueChange={(value, name) => {
            onChange(value);
          }}
          min={min}
          max={max}
          step={step ?? '0.01'}
          className={` bg-white w-full border-none py-2 pl-12 pr-3 text-neutral-800  placeholder:text-gray-600 outline-none sm:text-sm sm:leading-6 ${inputClass}`}
        />
      </div>
    </div>
  );
}
