export default function TextAreaField({
  label,
  value,
  onChange,
  className,
  inputClass,
  labelClass,
  onBlur,
  placeholder,
}) {
  return (
    <div className={className + ' mt-2'}>
      <label
        className={`block text-sm font-medium leading-6 text-neutral-800 ${labelClass}`}
      >
        {label}
      </label>
      <div className="mt-1">
        <textarea
          placeholder={placeholder}
          onBlur={onBlur}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          rows={4}
          className={`block bg-white w-full text-neutral-800 shadow border placeholder:text-gray-600 outline-none sm:py-1.5 sm:text-sm sm:leading-6 ${inputClass}`}
        />
      </div>
    </div>
  );
}
