export default function TextField({
  label,
  value,
  onChange,
  className,
  inputClass,
  labelClass,
  placeholder,
  onBlur,
  type,
  required,
}) {
  return (
    <div className={className + ' mt-2'}>
      <label
        className={`block text-sm font-medium leading-6 text-neutral-800 ${labelClass}`}
      >
        {label} {required && <span className="text-rose-600">*</span>}
      </label>
      <div className="mt-1">
        <input
          required={required}
          type={'password'}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={`block w-full border px-3 p-2 text-neutral-800 shadow placeholder:text-gray-600 outline-none sm:text-sm sm:leading-6 ${inputClass}`}
        />
      </div>
    </div>
  );
}
