import { useState, useEffect, useCallback } from 'react';
import {
  apiFetchCustomers,
  apiUpdateCustomer,
  apiDeleteCustomer,
} from '../service/buildrFi/customers';

const useCustomers = () => {
  const [data, setData] = useState([]);

  const fetchCustomers = useCallback(async () => {
    try {
      const result = await apiFetchCustomers();
      setData(result ?? []);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setData([]);
    }
  }, []);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const updateCustomer = async (updatedCustomerData) => {
    try {
      await apiUpdateCustomer(updatedCustomerData);
      fetchCustomers();
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };

  const deleteCustomer = async (customerId) => {
    try {
      await apiDeleteCustomer(customerId);
      fetchCustomers();
    } catch (error) {
      console.error('Error deleting customer:', error);
    }
  };

  return { data, updateCustomer, deleteCustomer };
};

export default useCustomers;
