import { api, api_delete } from '../../api.service';

export const apiFetchCustomers = async () => {
  try {
    const result = await api(`${process.env.REACT_APP_API_URL}/app/customer`);
    return result ?? [];
  } catch (error) {
    console.error('Error fetching customers:', error);
    return [];
  }
};

export const apiUpdateCustomer = async (editData) => {
  try {
    const response = await api(
      `${process.env.REACT_APP_API_URL}/app/customer`,
      { data: editData },
    );
    return response;
  } catch (error) {
    console.error('Error updating customer:', error);
    throw error;
  }
};

export const apiDeleteCustomer = async (id) => {
  try {
    const response = await api_delete(
      `${process.env.REACT_APP_API_URL}/app/customer/${id}`,
    );
    return response;
  } catch (error) {
    console.error('Error deleting customer:', error);
    throw error;
  }
};
