// Libs
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// Components
import PageHeader from '../components/page-header';
import FormWrapper from './form-wrapper';
// Fields
import CheckboxField from './fields/checkbox-field';

const DEFAULT_FORM_TITLE = 'Manage user';
const DEFAULT_SUCCESS_REDIRECT_URL = '/admin';
const GET_USER_INFO_URL = 'admin/user';
const PAGE_FOR_ADDING_USER_TO_BUSINESS = '/admin/business/[id]/user/create';

export default function UserForm({
  headline = DEFAULT_FORM_TITLE,
  successRedirectUrl = DEFAULT_SUCCESS_REDIRECT_URL,
  fieldSections = [],
  isSubscriptionDetailsEnabled = true,
  showBackButton = true,
  endpointUrl = GET_USER_INFO_URL,
  ...props
}) {
  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isFormForAddingUserToBusiness = useMemo(() => {
    const { id: businessId } = params;
    const urlWithoutId = pathname.replace(businessId, '[id]');

    return urlWithoutId === PAGE_FOR_ADDING_USER_TO_BUSINESS;
  }, [pathname]);

  const id = params.id ?? props.id;

  return (
    <>
      <PageHeader
        headline={headline}
        showBackButton={showBackButton}
      ></PageHeader>
      <div className="pt-4 sm:px-4 lg:max-w-6xl lg:mx-auto">
        <FormWrapper
          url={endpointUrl}
          id={id}
          {...props}
          callback={() => navigate(successRedirectUrl)}
          extraBodyData={isFormForAddingUserToBusiness && { businessId: id }}
        >
          {(values, setValues) => (
            <div key="user-form">
              {fieldSections.map((fieldSection) => (
                <div key={fieldSection.title.replaceAll(' ', '')}>
                  <h2 className="mt-5 mb-5 text-3xl font-bold text-neutral-800">
                    {fieldSection.title}
                  </h2>
                  <div className="grid grid-cols-2 gap-4">
                    {fieldSection.fields.map((field) => (
                      <field.component
                        id={field.id}
                        label={field.label}
                        required={field.required}
                        value={values[field.id]}
                        onChange={(newValue) =>
                          setValues({ ...values, [field.id]: newValue })
                        }
                        {...field.componentProps}
                      />
                    ))}
                  </div>
                </div>
              ))}
              {isSubscriptionDetailsEnabled && (
                <>
                  <div className="mt-10 mb-5 text-3xl font-bold tracking-tighter text-neutral-800">
                    Subscription Details
                  </div>

                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div className="flex flex-row items-center capitalize">
                      {/* incomplete, incomplete_expired, trialing, active, past_due, canceled, unpaid, or (custom) 'Ending on ddd DD MMM YYYY' */}
                      {`Subscription Status: ${
                        values?.stripeSubscriptionStatus?.replaceAll(
                          '_',
                          ' ',
                        ) ?? 'Inactive'
                      }`}
                    </div>
                    <CheckboxField
                      label="Has Free Access?"
                      value={values?.hasFreeAccess ?? false}
                      onChange={(v) =>
                        setValues({ ...values, hasFreeAccess: v })
                      }
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </FormWrapper>
      </div>
    </>
  );
}
