export default function NumberField({
  label,
  value,
  onChange,
  className,
  inputClass,
  labelClass,
  onBlur,
  required,
  min,
  max,
  step,
}) {
  return (
    <div className={className + ' mt-2'}>
      <label
        className={`block text-sm font-medium leading-6 text-neutral-800 ${labelClass}`}
      >
        {label} {required && <span className="text-rose-600">*</span>}
      </label>
      <div className="mt-1">
        <input
          required={required}
          onBlur={onBlur}
          type="number"
          value={value}
          min={min}
          max={max}
          step={step ?? 1}
          onChange={(e) => onChange(e.target.value)}
          className={`bg-white border-transparent block w-full py-2 text-neutral-800 shadow placeholder:text-gray-600 outline-none  ${inputClass}`}
        />
      </div>
    </div>
  );
}
